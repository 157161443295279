export default [
	"Hallo, ich bin der WineWizard, dein digitaler Weinberater",
	"Möchtest du deinen Lieblingswein finden oder den passenden Wein zum Essen? Ich helfe dir!",
	"Passend zum Essen",
	"Passend als Geschenk",
	"Passend zu meinem Geschmack",
	"Jetzt Lieblingswein finden",
	"Wofür suchst du Wein?",
	"Willkommen beim WineWizard.",
	"Ich habe folgenden Geschmackstypen:",
	"Folgende Aromen schmecken mir:",
	"Folgende Rotwein Rebsorten mag ich:",
	"Folgende Weißwein Rebsorten mag ich:",
	"Berücksichtige diese Infos bei deinen Empfehlungen.",
	"Gib mir zu diesen Infos Weinempfehlungen mit Hinweis auf 3 passende Rebsorten als kurzen Text mit maximal 400 Zeichen.",
	"Die Anfrage hat zu lange gedauert, versuche es bitte später noch einmal.",
	"Leider kann ich dir gerade keine Antwort geben, versuche es bitte später noch einmal.",
	"Erneut senden",
	"War diese Antwort hilfreich?",
	"Hier Speise eingeben",
	"Ja",
	"Nein",
	"Verkostung starten",
	"Jetzt kaufen",
	"Nicht verfügbar",
	"zur Karte",
	"Navigation öffnen",
	"Speichern",
	"Jetzt Entdecken",
	"Weinfarbe",
	"Preis",
	"Geschmack",
	"Marke",
	"Rebsorte",
	"Süßegrad",
	"Säuregehalt",
	"Etikett",
	"Zu modern",
	"Zu altbacken",
	"Zu billig",
	"Zu kompliziert",
	"Oh, das ist sehr schade.",
	"Woran lag es?",
	"Quiz Neustarten",
	"Beim laden des Quizzes ist ein Fehler aufgetreten.",
	"Bitte versuche es später noch einmal.",
	"Bewege den Slider, um eine Auswahl zu treffen.",
	"Für welchen",
	"Anlass",
	"suchen Sie einen Wein",
	"suchst du einen Wein",
	"Bitte auswählen",
	"Das Quiz wird beendet ...",
	"Achtung, Mehrfachauswahl möglich",
	"Mehrfachauswahl möglich",
	"Welcher Wein passt wohl zu dir?<br />Hinterlasse uns deine E-Mail-Adresse und du erfährst direkt welcher Wein-Typ du bist:",
	"E-Mail-Adresse",
	"Beim Absenden akzeptierst du unsere",
	"Datenschutzbestimmungen",
	"Name ist erforderlich.",
	"E-Mail-Adresse ist erforderlich.",
	"Falsches E-Mail Format.",
	"Sie müssen den Datenschutzbestimmungen zustimmen.",
	"Bitte wende dich an das Personal, um zu deinem Wein zu gelangen.",
	"Zurück",
	"Weiter",
	"Neustart",
	"Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuche es erneut.",
	"By starting the quiz you agree with our",
	"Mit dem Start des Quiz stimmst du unseren",
	"and our",
	"und der",
	"zu.",
	"AGBs",
	"AGB",
	"Ihr Geschmacksprofil ist",
	"Dein Geschmacksprofil ist",
	"Passend zum Essen",
	"Lesen Sie mehr zu Ihrem Geschmacks-Profil",
	"Lies mehr zu deinem Geschmacks-Profil",
	"Was macht Ihren",
	"Was macht deinen <underline>Lieblingswein</underline> aus?",
	"Neuen Lieblingswein finden",
	"Dein Geschmacksprofil wird analysiert und ausgewertet ...",
	"Ihr Geschmacksprofil wird analysiert und ausgewertet ...",
	"Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuche es erneut.",
	"NEUES GESCHMACKSPROFIL",
	"Diese Weine kannst du probieren",
	"Diese Weine passen perfekt zu Ihnen:",
	"Diese Weine passen perfekt zu dir:",
	"Passende Weissweine",
	"Passende Rotweine",
	"Gespeichert",
	"Schaumwein",
	"Leichter Weißwein",
	"Mittelschwerer Weißwein",
	"Voller Weißwein",
	"Rose",
	"Leichter Rotwein",
	"Mittelschwerer Rotwein",
	"Voller Rotwein",
	"Dessertwein",
	"Weinauswahl",
	"Farbe",
	"Dauer",
	"Fragen",
	"Vielen Dank für dein Feedback! \n Möchtest du zu einer weiteren Speise einen Wein finden?",
	"Weiteren Wein finden",
	"Weinquiz starten",
	"Zu welchem Essen möchtest du einen weiteren Wein finden?",
	"Alles klar",
	"Alles klar!",
	"Angebote genau nach deinem Geschmack",
	"Hier sind die aktuellen Angebote, passend zu deinem Geschmack:",
	"Zu welcher Speise suchen Sie den passenden Wein?",
	"Zu welcher Speise suchst du den passenden Wein?",
	"Hier Rebsorten eingeben",
	"mehr Anzeigen",
	"dein Geschmack",
	"Schmeckt zu",
	"Schließen",
	"Hallo & herzlich Willkommen!",
	"Wie kann ich dir helfen?",
	"Digitale Weinberatung",
	"Weinberatung",
	"Kundenservice kontaktieren",
	"Kundenservice",
	"Bevor wir starten",
	"Vielen Dank für dein Feedback! Möchtest du zu einer weiteren Speise einen Wein finden?",
	"Wie hat dir das Quiz gefallen?",
	"Vielen Dank für dein Feedback! Möchtest du einen Rabatt für deinen Lieblingswein erhalten?",
	"Lieblingswein finden",
	"Welchen Wein suchst du?",
	"Schwerer Rotwein zum Grillen",
	"Super-Suche starten",
	"Wein als Geschenk",
	"Wein zum Essen",
	"Hier findest du deinen neuen Lieblingswein",
	"schnell, einfach, kostenlos!",
	"❤️ In 60s zu deinem Lieblingswein!",
	"Digitalen Weinberater fragen",
	"Hey ich bin Karl, dein digitaler Barkeeper. Wie kann ich dir helfen?",
	"Jetzt Lieblingsdrink finden",
	"Wähle dein Cocktail-Abenteuer",
	"Mixen mit vorhandenen Zutaten",
	"Nach Rezept suchen",
	"Perfekten Drink für Anlass finden"
] as string[];
