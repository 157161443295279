
import { Cocktail } from "@/entities/domain/Match/Cocktail";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Translations } from "@/entities/domain/Translations";
import GetDocumentElementById from "@/utils/GetDocumentElementById";
import { defineComponent, PropType } from "vue";
import CocktailCard from "@/components/BaseComponents/CocktailCard.vue";
import TasteType from "./TasteType.vue";
import FloatingArrows from "@/components/BaseComponents/FloatingArrows.vue";


export default defineComponent({
    name: "CocktailResult",
    components: {
        CocktailCard,
        TasteType,
        FloatingArrows
    },
    props: {
        restaurant: {
            type: Object as PropType<Restaurant>,
            required: true,
        },
    },

    emits: ["answerClicked", "setAnswer", "closeQuiz"],
    data() {
        return {
            delay: 2000,
            loadingError: false,
            cocktailSelected: null as Cocktail | null,
            map: undefined,
            cocktailImageSelected: null as Cocktail | null,
            userReadDialog: false,

            isDialogOpened: false,

            filteredCocktailList: this.restaurant.Match?.Cocktails as Cocktail[],

            firstV2CardFlipped: true,

        };
    },
    computed: {
        innerWidth(): number {
            return window.innerWidth;
        },
        baseComponentTranslations(): Translations {
            return this.restaurant?.baseComponentTranslations ?? {};
        },

        headline(): string {
            if (this.restaurant.RestaurantQuizConfig.UseFormalSalutation)
                return this.restaurant.DisplayedTexts["Diese Cocktails passen perfekt zu Ihnen:"]

            return this.restaurant.DisplayedTexts["Diese Cocktails passen perfekt zu dir:"]
        },
    },
    created() {
        // Send Data to API to get Results
        sessionStorage.removeItem("infoWilliSlider");
    },
    mounted() {
        setTimeout(() => {
            this.firstV2CardFlipped = false;
        }, this.delay);
    },
    methods: {
        scrollToTasteProfile() {
            GetDocumentElementById("tasteProfile")?.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        },

        markdownText(text: string) {
            const count = text.match(/(\*\*)/g);
            let result = text;
            if (count && count.length > 0) {
                for (let i = 0; i < count.length; i++) {
                    result = result.replace("**", "<span style='font-weight: bold;'>");
                    result = result.replace("**", "</span>");
                }
            }

            return result;
        },



        getTextPartBeforeUnderline(text: string | null | undefined): string {
            if (!text) return "Was macht deinen";

            return text.substring(0, text.indexOf("<underline>"));
        },
        getTextPartInbetweenUnderline(text: string | null | undefined): string {
            if (!text) return "Lieblingswein";

            return text.substring(text.indexOf("<underline>") + 11, text.indexOf("</underline>"));
        },
        getTextPartAfterUnderline(text: string | null | undefined): string {
            if (!text) return "aus?";

            return text.substring(text.indexOf("</underline>") + 12);
        },


    },
});
