import Restaurant from "./Restaurant/Restaurant";
import displayedText from "@/assets/displayedText";

export default class DisplayedTexts {
	// For Dynamic extension
	[key: string]: string;


	constructor(restaurant: Restaurant) {

		// Fill DisplayedTexts with default texts
		displayedText.forEach((key) => {
			this[key] = key;
		});


		if(restaurant.FrontendDesign?.WineWizard?.TextConventions)
			Object.keys(restaurant.FrontendDesign?.WineWizard?.TextConventions).forEach((key) => {
				
				const answerVal = restaurant.FrontendDesign?.WineWizard?.TextConventions![key];
				
				this[key] = answerVal ?? key;
			})


		console.log(restaurant.FrontendDesign?.CocktailFinder?.TextConventions)

		if(restaurant.FrontendDesign?.CocktailFinder?.TextConventions)
			Object.keys(restaurant.FrontendDesign?.CocktailFinder.TextConventions).forEach((key) => {
				
				const answerVal = restaurant.FrontendDesign?.CocktailFinder?.TextConventions![key];
				
				this[key] = answerVal ?? key;
			})

		const translationTexts = restaurant.baseComponentTranslations;
		const selectedLanguage = restaurant.selectedLanguage;


		Object.keys(translationTexts).forEach((key) => {
			if (translationTexts[key] && translationTexts[key][selectedLanguage]) {
				this[key] = translationTexts[key][selectedLanguage];
			}
		})

		// Update object with translated texts if available
		Object.keys(this).forEach((key) => {
			if (translationTexts[key] && translationTexts[key][selectedLanguage]) {
				this[key] = translationTexts[key][selectedLanguage];
			}
		});

		// Apply text conventions if available
		if (restaurant.FrontendDesign?.WineWizard?.TextConventions) {
			const textConventions = restaurant.FrontendDesign.WineWizard.TextConventions;

			Object.keys(textConventions).forEach((key) => {
				const translationKey = textConventions[key];
				if (
					translationTexts[translationKey] &&
					translationTexts[translationKey][selectedLanguage]
				) {
					this[key] = translationTexts[translationKey][selectedLanguage];
				}
			});
		}
	}
}
