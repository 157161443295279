
import { defineComponent, PropType } from "vue";

import addToStyling from "@/utils/AddToStyling";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "BinaryChoice",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["answerClicked", "setAnswer"],
	data() {
		return {
			clickedAnswer: null,
		};
	},
	computed: {
		answers(): Answer[] {
			return this.restaurant?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
	mounted() {
		addToStyling(`
		#willi-wein .slider:before {
			background: url("${process.env.VUE_MEDIA_SPACE}img/tailorwine_ja_aktiv.svg");
		}
		#willi-wein input:checked + .slider:before {
			background: url("${process.env.VUE_MEDIA_SPACE}img/tailorwine_nein_aktiv.svg");
		}
		#willi-wein .BinaryChoice .slider:after {
			content: "${this.restaurant?.DisplayedTexts["Ja"].toUpperCase()}";
			color: #333333;
			font-family: var(--secondary-font-family), "Open Sans", sans-serif;
			display: block;
			position: absolute;
			transform: translate(-50%, -50%);
			top: 50%;
			right: ${this.restaurant?.selectedLanguage === 'de' ? "20%" : "10%"};
			font-size: 20px;
			font-weight: bold;
		}

		#willi-wein .BinaryChoice input:checked + .slider:after {
			content: "${this.restaurant?.DisplayedTexts["Nein"].toUpperCase()}";
			left: 35%;
			top: 50%;
			color: white;
		}
		`);

		this.$emit("answerClicked", null);
	},
	methods: {
		clickAnswer(answerIndex, answerValue) {
			this.clickedAnswer = answerIndex;
			this.$emit("setAnswer", {
				answerId: this.answers[answerIndex].Id,
				answerValue: answerValue,
			});
		},
	},
});
