
import { Wine } from "@/entities/domain/Match/Wine";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { WineColor } from "@/entities/enums/QuizConfigEnums";
import QuizService from "@/services/QuizService";
import axios, { AxiosRequestConfig } from "axios";
import { defineComponent, PropType } from "vue";
import WineCardV2 from "@/components/BaseComponents/WineCardV2.vue";
import Close from "@/components/svg/Close.vue";
import Loading from "@/components/Elements/Loading.vue";
import Arrow from "@/components/svg/Arrow.vue";

export default defineComponent({
	name: "BottleCard",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			required: true,
		},
		wineColor: {
			type: String,
			required: true,
		},
	},
	components: {
		WineCardV2,
		Close,
		Loading,
		Arrow,
	},
	data() {
		return {
			clickedGrape: null as string | null,
			wineList: null as Wine[] | null,
			loading: false
		};
	},
	computed: {
		grapes(): string[] {
			return (
				(this.wineColor === WineColor.RED
					? this.restaurant.Match?.Grapes?.Red
					: this.restaurant.Match?.Grapes?.White) ?? []
			);
		},
		label(): string {
			if (this.wineColor === WineColor.RED)
				return (
					this.restaurant.DisplayedTexts["Passende Rotweine"]
				);

			return (
				this.restaurant.DisplayedTexts["Passende Weissweine"]
			);
		},
	},
	methods: {
		async getWinesForGrape(grape: string) {

			if (!this.restaurant.RestaurantQuizConfig.ShowGrapeResultView) return;

			this.clickedGrape = grape;
			this.loading = true;

			const data = JSON.stringify({
				answer: grape,
				language: this.restaurant.selectedLanguage ?? "de",
			});

			const config = {
				method: "post",
				url: process.env.VUE_APP_API_SERVICE_PATH + "/tool/chat/recommendation",
				headers: {
					Authorization: "Bearer " + QuizService.token,
					"Content-Type": "application/json",
				},
				data: data,
			} as AxiosRequestConfig;

			try {
				const response = await axios(config);

				const responseWines = response.data
					.filter((el) => el)
					.map((el) => Wine.createFromApiResponse(el));

				this.wineList = responseWines;
				this.loading = false;

			} catch (error) {
				console.error({ error });
				this.loading = false;
				this.clickedGrape = null;
			}

		},
		closePopup() {
			this.clickedGrape = null;
			this.wineList = null;
		},
		scrollWineCards(direction: number) {
			const winecardContainer = this.$el.querySelector('.wineCardContainer') as HTMLElement;
			const winecard = winecardContainer.querySelector('.wineCardContainer > :first-child') as HTMLElement;
			const winecardWidth = winecard?.offsetWidth ?? 0;
			const winecardMargin = parseFloat(getComputedStyle(winecard).marginRight) + parseFloat(getComputedStyle(winecard).marginLeft);
			const scrollAmount = direction * (winecardWidth + winecardMargin);
			const containerWidth = winecardContainer.offsetWidth;
			const maxScrollPosition = winecardContainer.scrollWidth - containerWidth;
			let newScrollPosition = winecardContainer.scrollLeft + scrollAmount;

			if (newScrollPosition < 0) {
				newScrollPosition = 0;
			} else if (newScrollPosition > maxScrollPosition) {
				newScrollPosition = maxScrollPosition;
			} else {
				const cardIndex = Math.floor(newScrollPosition / (winecardWidth + winecardMargin));
				newScrollPosition = cardIndex * (winecardWidth + winecardMargin);
			}

			winecardContainer.scrollTo({
				left: newScrollPosition,
				behavior: 'smooth',
			});
		},
	}
});
