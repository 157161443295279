import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09490032"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "tt_wrapper",
  id: "resultPage"
}
const _hoisted_2 = { class: "explanation" }
const _hoisted_3 = {
  key: 0,
  class: "loading"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "restartInfo" }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_QRCodeComponent = _resolveComponent("QRCodeComponent")!
  const _component_CocktailResult = _resolveComponent("CocktailResult")!
  const _component_ProfileChoice = _resolveComponent("ProfileChoice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.restaurant.Quiz.getCurrentQuestion().Explanation), 1),
    (!_ctx.restaurant.Match || _ctx.loadingDelay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.errorWhileLoading)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.restaurant.RestaurantQuizConfig.UseFormalSalutation ?
					_ctx.restaurant.DisplayedTexts["Ihr Geschmacksprofil wird analysiert und ausgewertet ..."]
					:
					_ctx.restaurant.DisplayedTexts["Dein Geschmacksprofil wird analysiert und ausgewertet ..."]), 1))
            : _createCommentVNode("", true),
          (!_ctx.errorWhileLoading)
            ? (_openBlock(), _createBlock(_component_Loading, { key: 1 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.restaurant.DisplayedTexts["Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuche es erneut."]) + " ", 1),
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('restartQuiz')))
                  }, _toDisplayString(_ctx.restaurant.DisplayedTexts["NEUES GESCHMACKSPROFIL"]), 1)
                ])
              ]))
        ]))
      : (_ctx.match.QrCodeMode && !_ctx.restaurant.isSavedSessionLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_QRCodeComponent, {
              qrCodeUrl: _ctx.match.QrUrl ?? _ctx.hostUrl,
              onRestartQuiz: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('restartQuiz')))
            }, null, 8, ["qrCodeUrl"])
          ]))
        : (_ctx.restaurant.Match?.Cocktails)
          ? (_openBlock(), _createBlock(_component_CocktailResult, {
              key: 2,
              class: "component",
              restaurant: _ctx.restaurant,
              onCloseQuiz: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeQuiz')))
            }, null, 8, ["restaurant"]))
          : (_openBlock(), _createBlock(_component_ProfileChoice, {
              key: 3,
              class: "component",
              restaurant: _ctx.restaurant,
              onCloseQuiz: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeQuiz')))
            }, null, 8, ["restaurant"]))
  ]))
}