import { IAromaResponse } from "@/interfaces/api/responses/Matching/IAromaResponse";

export class AromaType{
    Name: string;
    Asset: string;

    constructor(aromaType: AromaType) {
        this.Name = aromaType.Name;
        this.Asset = aromaType.Asset;
    }

    static createFromApiResponse(response: IAromaResponse): AromaType{
        const newAromaType = {
            Name: response.name ?? response.Name,
            Asset: response.asset,
        } as AromaType;

        return newAromaType;
    }
}