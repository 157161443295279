import { ICocktailResponse } from "@/interfaces/api/responses/Matching/ICocktailResponse";

export class Cocktail {
	Name: string;
	Description: string;
	Recipe: string;
	DrinkType: string;
	Image: string;
	Spirits: string[];
	MatchingScore: number;

	constructor(cocktail: Cocktail) {
		this.Name = cocktail.Name;
		this.Description = cocktail.Description;
		this.Recipe = cocktail.Recipe;
		this.DrinkType = cocktail.DrinkType;
		this.Image = cocktail.Image;
		this.Spirits = cocktail.Spirits;
		this.MatchingScore = cocktail.MatchingScore;
	}

	static createFromApiResponse(response: ICocktailResponse): Cocktail {
		const newCocktail = {
			Name: response.Name,
			Description: response.Description,
			Recipe: response.recipe,
			DrinkType: response.DrinkType,
			Image: response.image,
			Spirits: response.spirits.map((spirit) => spirit.Name),
			MatchingScore: response.matchingScore,
		} as Cocktail;

		return newCocktail;
	}
}
