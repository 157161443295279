import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8451d42e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "williWein" }
const _hoisted_2 = {
  key: 0,
  class: "tooltip"
}
const _hoisted_3 = { class: "featureActions" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "chatbotNew"
}
const _hoisted_6 = {
  key: 1,
  class: "chatbotNew"
}
const _hoisted_7 = {
  key: 2,
  class: "chatbotNew"
}
const _hoisted_8 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatbotWindow = _resolveComponent("ChatbotWindow")!
  const _component_ChatbotWindowV3 = _resolveComponent("ChatbotWindowV3")!
  const _component_ChatbotWindowV4 = _resolveComponent("ChatbotWindowV4")!
  const _component_CocktailChatbotWindow = _resolveComponent("CocktailChatbotWindow")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.open)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "infoBubble",
            class: _normalizeClass({ focusMode: _ctx.focus })
          }, [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.showBubble)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (!_ctx.williTexts?.DenyText)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "infoBubbleClose",
                          onClick: _cache[0] || (_cache[0] = 
						() => {
							_ctx.showBubble = false;
							_ctx.focus = false;
						}
					)
                        }, " X "))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_ctx.williTexts?.MainText) + " ", 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", {
                        class: "deny",
                        onClick: _cache[1] || (_cache[1] = 
							() => {
								_ctx.showBubble = false;
								_ctx.focus = false;
							}
						)
                      }, _toDisplayString(_ctx.williTexts?.DenyText), 1),
                      _createElementVNode("div", {
                        class: "confirm",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.restaurant.Match ? _ctx.restaurant.openResult() : _ctx.restaurant?.startQuiz()))
                      }, _toDisplayString(_ctx.williTexts?.ConfirmText), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (
					!_ctx.restaurant.RestaurantQuizConfig.ChatbotVersion ||
					_ctx.restaurant.RestaurantQuizConfig.ChatbotVersion < 2
				)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _ctx.getImageFromSource(
						_ctx.restaurant?.FrontendDesign?.WilliImage ??
						'@/assets/img/Featurewilli_white.svg'
					)
						,
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onFeatureWilliClick && _ctx.onFeatureWilliClick(...args))),
                    onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showBubble = true))
                  }, null, 40, _hoisted_4))
                : _createCommentVNode("", true)
            ]),
            (
				_ctx.restaurant.RestaurantQuizConfig.ChatbotVersion &&
				_ctx.restaurant.RestaurantQuizConfig.ChatbotVersion == 2
			)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_ChatbotWindow, {
                    restaurant: _ctx.restaurant,
                    onStartQuiz: _cache[5] || (_cache[5] = ($event: any) => (_ctx.restaurant.startQuiz())),
                    onHardStartQuiz: _cache[6] || (_cache[6] = ($event: any) => (_ctx.restaurant.startQuiz(true))),
                    onOpenResult: _cache[7] || (_cache[7] = ($event: any) => (_ctx.restaurant.openResult()))
                  }, null, 8, ["restaurant"])
                ]))
              : _createCommentVNode("", true),
            (
				_ctx.restaurant.RestaurantQuizConfig.ChatbotVersion &&
				_ctx.restaurant.RestaurantQuizConfig.ChatbotVersion == 3 &&
				!_ctx.restaurant.RestaurantQuizConfig.CocktailFinderQuiz
			)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_ChatbotWindowV3, {
                    restaurant: _ctx.restaurant,
                    onStartQuiz: _cache[8] || (_cache[8] = ($event: any) => (_ctx.restaurant.startQuiz())),
                    onHardStartQuiz: _cache[9] || (_cache[9] = ($event: any) => (_ctx.restaurant.startQuiz(true))),
                    onOpenResult: _cache[10] || (_cache[10] = ($event: any) => (_ctx.restaurant.openResult()))
                  }, null, 8, ["restaurant"])
                ]))
              : (
				_ctx.restaurant.RestaurantQuizConfig.ChatbotVersion &&
				_ctx.restaurant.RestaurantQuizConfig.ChatbotVersion >= 4
			)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_ChatbotWindowV4, {
                      restaurant: _ctx.restaurant,
                      onStartQuiz: _cache[11] || (_cache[11] = ($event: any) => (_ctx.restaurant.startQuiz())),
                      onHardStartQuiz: _cache[12] || (_cache[12] = ($event: any) => (_ctx.restaurant.startQuiz(true)))
                    }, null, 8, ["restaurant"])
                  ]))
                : (_ctx.restaurant.RestaurantQuizConfig.CocktailFinderQuiz)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_CocktailChatbotWindow, {
                        restaurant: _ctx.restaurant,
                        onStartQuiz: _cache[13] || (_cache[13] = ($event: any) => (_ctx.restaurant.startCocktailQuiz())),
                        onHardStartQuiz: _cache[14] || (_cache[14] = ($event: any) => (_ctx.restaurant.startCocktailQuiz())),
                        onOpenResult: _cache[15] || (_cache[15] = ($event: any) => (_ctx.restaurant.openResult()))
                      }, null, 8, ["restaurant"])
                    ]))
                  : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}