import { ITasteTypeResponse } from "@/interfaces/api/responses/Matching/ITasteTypeResponse";

export class TasteType{
    Name: string;
    Description: string;
    WineDescription: string;
    Asset: string

    constructor(tasteType: TasteType) {
        this.Name = tasteType.Name;
        this.Description = tasteType.Description;
        this.WineDescription = tasteType.WineDescription;
        this.Asset = tasteType.Asset;
    }

    static createFromApiResponse(response: ITasteTypeResponse): TasteType{
        const newTasteType = {
            Name: response.name ?? response.Name,
            Description: response.description ?? response.Description,
            WineDescription: response.wine_description ?? response.DrinkDescription,
            Asset: response.asset,
        } as TasteType;

        return newTasteType;
    }
}