import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/2231_tw_flasche_weisswein.svg'
import _imports_1 from '@/assets/img/2231_tw_flasche_rotwein.svg'


const _withScopeId = n => (_pushScopeId("data-v-5cb3b697"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  src: _imports_0
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1
}
const _hoisted_3 = { class: "wineInfos" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "popup"
}
const _hoisted_6 = { class: "wineCards" }
const _hoisted_7 = {
  key: 1,
  class: "wineCardContainer"
}
const _hoisted_8 = {
  key: 2,
  style: {"color":"black","width":"100%","display":"flex","justify-content":"space-between","position":"absolute"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _component_WineCardV2 = _resolveComponent("WineCardV2")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bottleCard", { whiteWine: _ctx.wineColor === 'WHITE', redWine: _ctx.wineColor === 'RED' }])
  }, [
    (_ctx.wineColor === 'WHITE')
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2)),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.grapes, (grape) => {
        return (_openBlock(), _createElementBlock("div", {
          key: grape,
          class: "singleWineType",
          onClick: ($event: any) => (_ctx.getWinesForGrape(grape))
        }, _toDisplayString(grape), 9, _hoisted_4))
      }), 128)),
      (_ctx.clickedGrape)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (!_ctx.loading)
                ? (_openBlock(), _createBlock(_component_Close, {
                    key: 0,
                    class: "close",
                    onClick: _ctx.closePopup,
                    fill: "#333"
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.wineList)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wineList?.slice(0, 5), (wine) => {
                      return (_openBlock(), _createBlock(_component_WineCardV2, {
                        class: "card",
                        key: wine.Name,
                        restaurant: _ctx.restaurant,
                        wine: (wine ?? {}),
                        onWineClicked: ($event: any) => (_ctx.$emit('wineClicked', wine))
                      }, null, 8, ["restaurant", "wine", "onWineClicked"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_Arrow, {
                      class: "navigationArrow",
                      style: {"transform":"rotate(180deg)"},
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollWineCards(-1)))
                    }),
                    _createVNode(_component_Arrow, {
                      class: "navigationArrow",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollWineCards(1)))
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_Loading, {
                    key: 3,
                    loading: _ctx.loading,
                    restaurant: _ctx.restaurant
                  }, null, 8, ["loading", "restaurant"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}