
import Loading from "@/components/BaseComponents/Loading.vue";
import ProfileChoice from "@/components/Elements/Result/ProfileChoice.vue";
import CocktailResult from "@/components/Elements/Result/CocktailResult.vue";
import QRCodeComponent from "@/components/QRCodeComponent.vue";
import { Match } from "@/entities/domain/Match/Match";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Translations } from "@/entities/domain/Translations";
import AutoScrollToId from "@/utils/AutoScrollToId";
import GetDocumentElementById from "@/utils/GetDocumentElementById";
import { defineComponent, nextTick, PropType } from "vue";
import CocktailResultVue from "@/components/Elements/Result/CocktailResult.vue";

export default defineComponent({
	name: "QuizResultPage",
	components: { ProfileChoice, Loading, QRCodeComponent, CocktailResult },
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			required: true,
		},
	},
	data() {
		return {
			loadingDelay: 2000,
			errorWhileLoading: false,
			scrolledToBottom: false,
		};
	},
	watch: {
		restaurant: {
			handler(newVal: Restaurant) {
				if (newVal.Match && this.loadingDelay === 0 && !this.scrolledToBottom)
					this.startAutoScroll();
			},
			deep: true,
		},
	},
	computed: {
		baseComponentTranslations(): Translations | undefined {
			return this.restaurant?.baseComponentTranslations;
		},
		hostUrl() {
			return window.location.host;
		},
		match(): Match {
			return this.restaurant.Match ?? ({} as Match);
		},
	},
	methods: {
		async setAnswer(event) {
			await this.restaurant.setAnswerAsync(event);
		},
		onAnswerClicked(event) {
			this.restaurant.onAnswerClicked(event);
		},
		scrollToAsync(val) {
			return new Promise(function (resolve) {
				setTimeout(function () {
					resolve(GetDocumentElementById("rootContainer")?.scrollTo(0, val));
				}, 0.1);
			});
		},
		async startAutoScroll() {
			await nextTick(); // Otherwhise the autoscrolling happens to early

			this.scrolledToBottom = true;

			AutoScrollToId("profileFooter", "auto", "end");

			// setTimeout(() => AutoScrollToId("header", "smooth", "start"), 500);

			const scrollHeight = GetDocumentElementById("rootContainer")!.scrollHeight;

			let scrollTo = scrollHeight;

			const stepSize = 8;

			let i = 0;

			while ((i < scrollHeight / stepSize) && window.location.hash.includes("result")) {
				await this.scrollToAsync(scrollTo);
				scrollTo -= stepSize;
				i++;
			}
		},
	},
	mounted() {
		setTimeout(() => {
			this.loadingDelay = 0;

			if (!this.scrolledToBottom && this.restaurant.RestaurantQuizConfig.WilliAsFeature?.RunInFeatureMode) this.startAutoScroll();
		}, this.loadingDelay);

		setTimeout(() => {
			if (!this.restaurant.Match || this.loadingDelay) this.errorWhileLoading = true;
		}, 5000);
	},
});
